import { Timestamp } from 'firebase/firestore';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import FeudleArchive from '../../components/Feudle/archive';
import Feudle from '../../components/Feudle/index';
import Logo from '../../components/Logo';

type FeudleType = {
	date: Timestamp;
	query: string;
	answer: string;
};

const FeudleArchivePage = () => {
	const [feudle, setFeudle] = React.useState<FeudleType | undefined>();
	const params =
		globalThis.window && new URLSearchParams(window.location.search);
	const load = params?.get('query');

	return (
		<>
			<Helmet>
				<title>Google Feud - Feudle - Archive</title>
			</Helmet>
			<Logo isFeudle />
			{feudle && (
				<div style={{ marginBottom: '10rem' }}>
					<Feudle key={feudle.query} load={feudle} />
				</div>
			)}
			<FeudleArchive load={load} setFeudle={setFeudle} />
		</>
	);
};
export default FeudleArchivePage;
